import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  LinearProgress,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  Container,
  ListItemText,
} from "@mui/material";
import ClientApi from "../services/ClientApi";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TaskIcon from "@mui/icons-material/Task";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const Dashboard = () => {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("Your Company Name");
  const [name, setName] = useState("Our Beloved Client");
  const [tasks, setTasks] = useState([]);
  const [recentActivities, setRecentActivities] = useState([]);
  const [pendingHours, setPendingHours] = useState(5);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [hourBalance, setHourBalance] = useState(26);
  const [recentPackageHours, setRecentPackageHours] = useState(0);
  const renderLines = 3;
  const chartData = {
    labels: ["Used Hours", "Remaining Hours", "Pending Hours"],
    datasets: [
      {
        data: [
          recentPackageHours - hourBalance,
          Math.max(hourBalance, 0),
          pendingHours,
        ],
        backgroundColor: ["#FF6384", "#A1DF50", "#D3D3D3"],
        hoverBackgroundColor: ["#FF6384", "#A1DF50", "#D3D3D3"],
      },
    ],
  };

  const handlePurchaseHours = () => {
    navigate("/packages");
  };
  const handleSeeMoreActivities = () => {
    navigate("/activities");
  };
  const handleViewAllTasks = () => {
    navigate("/tasks");
  };

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { width } = chart;
      const { height } = chart;
      const ctx = chart.ctx;

      ctx.restore();
      const fontSize = (height / 250).toFixed(2); // Scale font size
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = `${chart.config.options.centerText}`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillStyle = "#000";
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };
  ChartJS.register(ArcElement, Tooltip, Legend, centerTextPlugin);

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.label}: ${tooltipItem.raw} hours`,
        },
      },
    },
    centerText: `${hourBalance} Hours`,
    maintainAspectRatio: false, // Ensures chart resizes properly
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseActivities = await ClientApi.get("activities"); // Adjust the API endpoint based on your backend routes
        const { company, allActivities: timeActivities } =
          responseActivities.data;
        const responseOpenTasks = await ClientApi.get("tasks");
        const { tasks } = responseOpenTasks.data;
        setTasks(tasks.sort((a, b) => (a.timeStamp > b.timeStamp ? -1 : 1)));
        setCompanyName(company.companyName);
        var approvedTimeBal = timeActivities
          .filter((ta) => ta.status === "completed")
          .reduce((a, b) => a + b.hours, 0);
        var pendingHours = timeActivities
          .filter(
            (ta) => ta.activityType === "TimeAdd" && ta.status === "pending"
          )
          .reduce((a, b) => a + b.hours, 0);
        setHourBalance(approvedTimeBal.toFixed(2));
        setPendingHours(pendingHours.toFixed(2));
        setRecentActivities(
          timeActivities
            .filter((ta) => ta.status === "completed")
            .sort((a, b) => (a.timeStamp > b.timeStamp ? -1 : 1))
            .slice(0, renderLines)
        );
        const mostRecentTimeAdd = timeActivities
          .filter(
            (activity) =>
              activity.activityType === "TimeAdd" &&
              activity.status === "completed"
          )
          .reduce(
            (mostRecent, current) =>
              !mostRecent ||
              new Date(current.timeStamp) > new Date(mostRecent.timeStamp)
                ? current
                : mostRecent,
            null
          );
        setRecentPackageHours(mostRecentTimeAdd?.hours || 0);
        // setPendingActivities(
        //   timeActivities
        //     .filter(
        //       (ta) => ta.status === "pending" && ta.activityType === "TimeAdd"
        //     )
        //     .slice(0, renderLines)
        // );
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError("Error getting data from the server.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ padding: 3, bgcolor: "#f4f6f8", minHeight: "100vh" }}>
      {error && (
        <Typography color="error" align="center" marginBottom="30px">
          {error}
        </Typography>
      )}

      {/* Header */}
      <Typography variant="h4" gutterBottom>
        <strong>{companyName}</strong>
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        Here's a summary of your account and recent activities.
      </Typography>

      {loading ? (
        <Container>
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Container>
      ) : (
        <Grid container spacing={3}>
          {/* Hour Balance: Spans 2 columns */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom justifySelf="center">
                  Hour Balance
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 350,
                  }}
                >
                  <Doughnut data={chartData} options={chartOptions} />
                </Box>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePurchaseHours}
                  >
                    Purchase More Hours
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Open Tasks */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Open Tasks ({tasks.length})
                  </Typography>
                </Box>
                <List>
                  {tasks.length > 0 ? (
                    tasks.slice(0, renderLines).map((task) => (
                      <ListItem key={task.id}>
                        <ListItemText
                          primary={task.title}
                          secondary={task.description}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No open tasks.
                    </Typography>
                  )}
                </List>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleViewAllTasks}
                  >
                    View All Tasks
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Activity Overview */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Activity Overview
                </Typography>
                <List>
                  {recentActivities.length > 0 ? (
                    recentActivities.map((activity) => (
                      <ListItem key={activity.id}>
                        <ListItemText
                          primary={`${
                            activity.description
                          }${` (${activity.hours} hours)`}`}
                          secondary={`Date: ${new Date(
                            activity.timeStamp
                          ).toDateString()}`}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No recent activity.
                    </Typography>
                  )}
                </List>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleSeeMoreActivities}
                  >
                    View All Activities
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Dashboard;
